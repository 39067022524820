import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import Carousel from "react-bootstrap/Carousel";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import "./AboutUs.css";

import productImage3 from "../../assets/aboutus/about-us-img.png";

function AboutUs() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Container className="about-us-container">
        <Breadcrumb />
        
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="title-section"
        >
          <h1 className="main-title">About Orbitz</h1>
          <div className="title-underline"></div>
        </motion.div>

        <motion.div 
          className="content-wrapper"
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4 }}
        >
          <div className="image-container">
            <img
              src={productImage3}
              alt="oil pipe"
              className="about-image"
            />
          </div>
          <div className="text-container">
            <h2 className="section-title">Who We Are</h2>
            <p className="description">
              We are the premier trader & Provider of engineering,
              construction, and trading in the state of Qatar. Moreover,
              we are the major suppliers of oil field equipment,
              electrical & plumbing accessories, hardware materials,
              safety materials, paint materials, and all construction
              materials. We can also supply other similar items as per
              your requirements with skilled employees and vast
              experience. The achievement is the ability to provide the
              highest quality products and services on time. The products
              and services we supply are proven dependable and backed by
              the best warranties and best products from the international
              market. We represent and deal with several companies,
              manufactures and stockiest worldwide with our support team
              in the Middle East. We are in a position to offer you
              competitive prices and quick deliveries with the highest
              levels of quality, safety, and technical expertise.
            </p>
          </div>
        </motion.div>
      </Container>
    </motion.div>
  );
}

export default AboutUs;